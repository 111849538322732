import { graphql,navigate } from "gatsby"
import React from "react"
import { Container, Image, Row, Col, Form } from "react-bootstrap"
import Footer from "../components/footer/footer"
import NavigationBar from "../components/navbar/navigationBar"
import ReactMarkdown from "react-markdown"
import BlogCard from "../components/blog/blogCard"
import Select from "react-select"
import "./article.less"
const Article = props => {
  const { data } = props
  const selectOptions = data.allArticles.nodes?.map((item, index) => {
    return {
      value: item.id,
      label: item.Title,
    }
  })
  console.log(selectOptions)
  const handleSelect = option => {
    navigate(`/article/${option.value}`);
  }
  return (
    <div className="article-container">
      {console.log(selectOptions)}
      <Image src={data.strapiArticles.Image.localFile.publicURL} className="blog-img"></Image>
      <NavigationBar />
      <Container>
        <Row>
          <Col className="header">
            <p>{data.strapiArticles.published_at}</p>
            <h1 className="display-1">{data.strapiArticles.Title}</h1>
          </Col>
        </Row>
        <Row className="article-body">
          <Col md={8}>
            <ReactMarkdown>{data.strapiArticles.ArticleBody}</ReactMarkdown>
          </Col>
          <Col md={4}>
            <Form>
              <Form.Group>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={selectOptions}
                  onChange={handleSelect}
                  placeholder="&#x1F50D; Search"
                ></Select>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
      <div className="bg-row">
        <Container>
          <Row className="related-container">
            <h4 className="my-4">Related Articles</h4>
            {data.strapiArticles?.RelatedArticles?.articles?.map((item, index) => {
              if (index < 3) {
                console.log(item)
                return (
                  <Col key={index} md={4}>
                    <BlogCard
                      id={item.id}
                      title={item.Title}
                      text={item.ArticleBody}
                      date={item.published_at}
                      imgUrl={item.Image.localFile.publicURL}
                    />
                  </Col>
                )
              }
            })}
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($articleId: String!) {
    strapiArticles(id: { eq: $articleId }) {
      id
      Title
      ArticleBody
      published_at(formatString: "DD.MM.YYYY")
      Image {
        localFile {
          publicURL
        }
      }
      RelatedArticles {
        articles {
          id
          Title
          published_at(formatString: "DD.MM.YYYY")
          ArticleBody
          Image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allArticles: allStrapiArticles {
      nodes {
        id
        Title
      }
    }
  }
`
